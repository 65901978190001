.logo{
        background: white;
        padding: 0.5rem;
        color: #575860;
        border: none;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        font-weight: 600;
        max-width: 6rem;
        max-height: 3rem;
        display:flex;
        align-items: center;
        gap:0.3rem;
        cursor: pointer;
}

.img{
    width:100%;
    height:100%;
}

.img_wrapper{
    width: 2rem;
    height: 2rem;
}

.large {
    padding: 0.8rem;
    font-size: 1.3rem;
}

.medium {
    padding: 0.5rem;
    font-size: 1rem;
}

.small {
    padding: 0.3rem;
    font-size: 0.8rem;
}