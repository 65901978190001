.btn {
    background: white;
    padding: 0.5rem;
    color: #575860;
    border: none;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content:center;
    gap:0.4rem;
    min-height: 1rem;
    min-width: 2rem;
    text-align: center;
    transition: filter 0.3s ease;
    vertical-align: middle;
}

.btn:hover {
    filter: brightness(0.9);
}

.large {
    padding: 0.8rem;
    font-size: 1.3rem;
}

.medium {
    padding: 0.5rem;
    font-size: 1rem;
}

.small {
    padding: 0.3rem;
    font-size: 0.8rem;
}

.btn:disabled{
    cursor: not-allowed;
    background-color:rgb(255, 255, 255);
    color: #c3c3c3;
}

