
  .accordion_wrapper {
    border: solid 1px #ccc;
    background-color:white;
    border-radius: .5rem;
    position: relative;
    overflow: hidden;
  }
  
  .accordion_item {
    height: auto;
    width: 100%;
    max-height: 100%;
  }
  
.collapsed {
    display: none;
    max-height: 0;
  }
  
  .accordion_title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion_bottom{
    border-bottom: solid 1px #ccc;
  }
  
  .accordion_content {
    background:white;
    padding: 1rem;
    height: 100%;
    width:100%;
    z-index: 99999;
  }
  