.accordion .rdtPicker {
    position: static;
    max-width: 100%;
}

h1,h2,h3,h4,h5{
    color:#575860;
}
label{
    color:#575860;
    font-weight: 500;
    font-size:1.2rem;
}
.app{
    height: 100%;
    width: 100vw;
    overflow-x: hidden;
    flex-direction: column;
    display:flex;
    justify-content: center;
    align-items: center;
}

.container-custom{
    width: 80%;
    min-width:80%;
    min-height: 80vh;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    padding:0.1rem;
    margin-bottom: 0.5rem;
}

.hamburger {
    display:none;
}

  @media (max-width: 820px) {
    .hamburger {
        display:block;
    }
}
