.holder {
    background: white;
    padding: 0.5rem;
    color: #575860;
    border: none;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content:space-between;
    gap:0.4rem;
    min-height: 1rem;
    min-width: 2rem;
    text-align: center;
    transition: filter 0.3s ease;
    vertical-align: middle;
}

.disabled{
    background-color: rgb(188, 188, 188);
    cursor: not-allowed;
    color: rgb(179, 179, 179);
}


.holder:hover {
    filter: brightness(0.9);
}
