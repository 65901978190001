.header {
    width: 100%;
    height: 4rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.container {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1.5rem;
    /* background-color: yellow; */
    justify-content: space-between;
}

.child {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    /* background-color: rgb(210, 210, 255); */
}

.navigation {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.mobile {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(45, 143, 255, 0.973);
    padding: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
}

.mobile .link {
    font-size: 1.5rem;
    font-weight: 400;
    color: white;
    cursor: pointer;
}


.mobile .exit {
    font-size: 4.3rem;
    right: 3rem;
    top: 1rem;
    color: white;
    position: absolute;
    cursor: pointer;
}

.mobile .container {
    height: 90%;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
}

.mobile .title {
    color: white;
}

.wrapper {
    background: rgb(255, 255, 255);
    border-radius: 8px;
    display: inline-block;
    width: 80%;
    height: 4rem;
    display: flex;
    font-weight: 300;
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    
}

.link {
    text-decoration: none;
    color: #575860;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 0.2rem;
    border-radius: 5px;
}

.link:hover {
    background-color: #f4f4f4;
}

.alert-enter {
    opacity: 0;
}

.alert-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

@media only screen and (max-width: 420px) and (max-width: 479px) {

    .container {
        padding: 0rem;
    }

    .navigation {
        display: none;
    }

    .mobile .title {
        font-size: 3rem;
    }

    .mobile .link {
        font-size: 1.7rem;
    }

}

@media only screen and (min-width: 479px) and (max-width: 825px) {
    .header {
        padding: 0.5rem;
    }

    .wrapper {
        padding-top: 1rem;
    }

    .mobile .title {
        font-size: 5rem;
    }

    .mobile .link {
        font-size: 2.5rem;
    }

    .container {
        padding: 0rem;
    }

    .navigation {
        display: none;
    }

    .child {
        gap: 0.8rem;
    }
}

/* @media only screen and (min-width: 600px) {...} */
/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */
/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */