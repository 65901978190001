.select{
    border:1px solid rgb(211, 211, 211);
    border-radius: 3px;
    padding:0.2rem;
    min-width: 10rem;
    width: 100%;
    min-height: 1rem;
    display: block;
}

.select::placeholder{
    color:#D8D8D8;
}

.select:focus{
  border-color:  rgb(164, 163, 163);
  outline:none;
}