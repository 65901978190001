.modal{
    height:100vh;
    width:100vw;
    background-color:rgba(0,0,0,0.4);
    position: fixed;
    top:0;
    left:0; 
    display:flex;
    justify-content:center;
    align-items:center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 999;
}

.modal.active{
    opacity:1;
    transform: scale(1);
    pointer-events: all;
}

.content{
    padding:20px;
    border-radius: 12px;
    background-color:rgb(255, 255, 255);
    min-width: 30vw;
}

@media only screen and (min-width: 479px) and (max-width: 825px) {
 
}
