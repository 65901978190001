.alert{
    background-color:rgb(236, 108, 108);
    padding:0.5rem;
    border-radius: 5px;
}

.primary{
    background-color: rgb(115, 164, 234);
}
.info{
    background-color:rgb(176, 176, 176);
}
.success{
    background-color: rgb(140, 207, 144);
}
.warning{
    background-color: rgb(246, 228, 123);
}
.danger{
    background-color: rgb(218, 137, 137);
}
