.primary {
    background: #2E8EFF;
    color: white;
}
.light {
    background: #ffffff;
    color: #575860;
}
.success {
    background: #2fb15f;
    color: white;
}
.warning {
    background: #ffe32e;
    color: white;
}
.danger {
    background: #f13737;
    color: white;
}