.textArea{
    width:100%;
    display:block;
    border:1px solid rgb(211, 211, 211);
    border-radius: 3px;
    padding:0.2rem;
    min-width: 20rem;
}

.textArea::placeholder{
    color:#D8D8D8;
}

.textArea:focus{
  border-color:  rgb(164, 163, 163);
  outline:none
}
