.modal_footer{
  display:flex;
  gap:0.5rem;
  justify-content: flex-end;
}

.modal_header{
  display:flex;
  justify-content: space-between;
}

.modal_title{
  font-size:1.7rem;
  font-weight:bold;
}

.modal_small{
  display:block;
  font-size: 0.8rem;
  margin-bottom:0.3rem;
}

.wrapper{
  display:flex;
  flex-direction:column;
  gap:1rem;
}

.link{
  text-decoration: none;
}
.label{
  display:block;
}

.hr{
  height:0rem;
  border-top: 1px solid rgb(56, 56, 56);
}

@media only screen and (max-width: 420px) and (max-width: 479px) {

  .modal_title{
      font-size:1.2rem;
  }

}

@media only screen and (min-width: 479px) and (max-width: 825px) {

  .modal_container{
      width:30rem;
  }

  .label{
      font-size:1.5rem;
  }

  .modal_title{
      font-size:2rem;
  }

}
